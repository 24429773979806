import React, {useState} from 'react';
import { navigate } from 'gatsby';
import {useWebStoreContext} from "../contexts/WebStoreContext";
import CONTINENTS_COUNTRIES from "./countrySelect/continentsCountries.json"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import {
    isDistributorOptional,
    isDistributorCountry,
    isDistributorOnly,
    getDistributorTab,
    redirectIfRequired
} from "../utils/webStore";
import styles from './countrySelect/style.module.css';
import UiButton from '../components/Ui/UiButton';
import { NanoGrid, NanoGridItem, NanoSelect} from '@nanoporetech-digital/components-react';

// function importFlags(r) {
//     let images = {};
//     r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
//     return images;
// }
//
// const flags = importFlags(require.context('./images/flags', false, '/\.svg/'));

// const images = require.context('./countrySelect/images/flags/', false);
// console.log("images", images)
// const loadImage = imageName => (images(`./${imageName}`).default);

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../assets/flags/', false, /\.(png|jpe?g|svg)$/));

export default function CountrySelectPage(props) {
    const {webstore, setWebstore} = useWebStoreContext();
    const [textFilter, setTextFilter] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalCountryIso, setModalCountryIso] = useState('')

    const openModal = () => {
        setModalIsOpen(true)
    }
    const closeModal = () => {
        setModalIsOpen(false)
    }

    const handleCountryClick = countryIso => {
        if (isDistributorCountry(countryIso)) {
            setModalCountryIso(countryIso)
            openModal()
        }
        setWebstore({
            ...webstore,
            countryIsoPreference: countryIso,
            designatedStore: '',
        })
        if (isDistributorCountry(countryIso)) {
            setModalCountryIso(countryIso);
            openModal();
        } else {
            navigate('/');
        }

    }

    const redirectToDistributorsPage = tab => {
        let url = "https://nanoporetech.com/services/distributors"
        if (tab) {
            url += `#tabs-0=${tab}`
        }
        window.location.replace(url)
    }

    return (
        <div className="contentWrapper">
            <h1>Choose your country or region</h1>

            <div className={styles.controls}>
                <TextFilter textFilter={textFilter} setTextFilter={setTextFilter} />
                <div>
                    Already have an account?&nbsp;&nbsp;
                    <UiButton className="button button--primary">Log in</UiButton>
                </div>
            </div>

            {CONTINENTS_COUNTRIES
                .filter(continent =>
                    continent.countries.findIndex(country =>
                        country.label.toLowerCase().includes(textFilter.toLowerCase())
                    ) !== -1
                )
                .map(continent =>
                    <div key={continent.code} className={styles.countriesContainer}>
                        <h2>{continent.label}</h2>

                        <NanoGrid l-cols="6" s-cols="4" xl-cols="10" className={styles.countries}>
                            {continent.countries
                                .filter(country =>
                                    country.label.toLowerCase().includes(textFilter.toLowerCase())
                                )
                                .map(country =>
                                    <NanoGridItem  grid-states="xl-col-span-1 l-col-span-1 s-col-span-1" key={country.code} className={styles.countryFlag}>
                                        <img width="24" src={images[country.code.toLowerCase() + '.svg']} />
                                        <a href="#"
                                        onClick={
                                            () => handleCountryClick(country.code)
                                        }
                                        >{country.label}</a>
                                    </NanoGridItem>
                                )
                            }
                        </NanoGrid>
                    </div>
                )
            }
            <Modal
                isOpen={modalIsOpen}
            >
                <div>
                    Modal content
                </div>
                <div>
                    {isDistributorOptional(modalCountryIso) &&
                        <div>
                            To continue to purchase directly with us please click continue.
                            Alternatively, please click the link below to see available distributors.
                            <button onClick={() => {
                                setWebstore({...webstore, designatedStore: "default"})
                            }}>
                                Continue
                            </button>

                            <button onClick={() => {
                                redirectToDistributorsPage(getDistributorTab(modalCountryIso))
                            }}>
                                Available distributors
                            </button>
                        </div>
                    }

                    {isDistributorOnly(modalCountryIso) &&
                        <div>
                            Please click the link below to view the available distributors.
                            <button onClick={() => {
                                redirectToDistributorsPage(getDistributorTab(modalCountryIso))
                            }}>
                                Available distributors
                            </button>
                        </div>
                    }
                </div>
                <div>

                </div>
                <button onClick={closeModal}>Close</button>
            </Modal>
        </div>
    )
}

const TextFilter = ({textFilter, setTextFilter}) => {
    return (
        <div className={styles.filter}>
            <input
                className="form__entry-input"
                data-cy="countrySearch"
                placeholder="Search"
                type="text"
                value={textFilter}
                onChange={e => setTextFilter(e.currentTarget.value)}
            />
            <span><FontAwesomeIcon icon={faSearch} /></span>
        </div>
    )
}

const setCountry = (country, webstore, setWebstore) => {
    // if (typeof setWebstore === "function") {
    //     setWebstore({
    //         ...webstore,
    //         storePreference: country
    //     })
    // }
}
